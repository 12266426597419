import React from 'react';
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from 'tcweb-material-components/core/box'

import Link from './link'

const useClasses = makeStyles(({ palette, accessibility: { highContrast } }) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        display: 'flex',
        alignContent: 'center',
        fill: '#FFFFFF',
        color: '#FFFFFF',
        border: `2px solid ${palette.secondary.border}`,
        borderColor: palette.secondary.border,
        background: palette.secondary.main,
        fontSize: '30px',
        padding: '20px 40px',
        ['&:hover, &:focus']: {
            background: palette.secondary.main,
            border: '2px solid #FFFFFF',
            fill: '#FFFFFF',
            color: '#FFFFFF',
            [highContrast.mediaQuery('active')]:{
                border: '2px solid Highlight',
                backgroundColor: 'buttonFace'
            }
        }
    },
    icon: {
        [highContrast.mediaQuery('active')]:{
            color: 'buttonText'
        }
    },
    rotate: { transition: 'transform 200ms', transform: 'rotate(180deg)' }
}))

const PageControls = ({ next, prev, ariaLabelPrev, ariaLabelNext, ...rest }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
    const _classes = useClasses()
    const { t } = useTranslation()

    if (!next && !prev) return null

    return (
        <Box {...rest}>

            <Box className={_classes.buttonContainer} height={{ xs: '135px', md: '225px'}}>
                <div>
                    {prev &&
                        <div>
                        <Link href={prev.path || `/${prev.slug}`}
                            className={_classes.button}
                            title={ariaLabelPrev || t('Previous Item')}
                            aria-label={ariaLabelPrev || t('Previous Item')}
                            style={{ justifyContent: 'flex-start', paddingLeft: 30 }} >
                            <div className={`page-controls-icon ${_classes.icon}`} style={!isMobile ? { 'marginRight': 20 } : {'margin': 0}}>
                                <FontAwesomeIcon icon={['fas', 'arrow-right-long']} className={_classes.rotate} />
                            </div>
                            {!isMobile && t('Previous Item')}
                        </Link>
                        </div>
                    }
                </div>
                <div>
                    {next &&
                        <div>
                        <Link href={next.path || `/${next.slug}`}
                            className={_classes.button}
                            title={ariaLabelNext || t('Next Item')}
                            aria-label={ariaLabelNext || t('Next Item')}
                            style={{ justifyContent: 'flex-end', paddingRight: 30 }}>
                            {!isMobile && t('Next Item')}
                            <div className={`page-controls-icon ${_classes.icon}`} style={!isMobile ? { 'marginLeft': 20 } : {'margin': 0}}>
                                <FontAwesomeIcon icon={['fas', 'arrow-right-long']} />
                            </div>
                        </Link>
                        </div>
                    }
                </div>
            </Box>
        </Box>
    )
}

export default PageControls
