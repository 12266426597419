import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'tcweb-material-components/core/button'
import classnames from 'classnames'
import { darken } from '@material-ui/core/styles/colorManipulator'

const CARET_BUTTON_SIZE = 60

const useClasses = makeStyles(({ palette }) => ({
    root: {
        borderBottom: `1px solid ${palette.border.primary}`
    },
    header: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: `auto ${CARET_BUTTON_SIZE}px`
    },
    fullHeader: {
        width: '100%'
    },
    headerComponent: {
        flex: 1,
        height: '100%'
    },
    headerComponentSelected: {
        backgroundColor: palette.primary.main,
    },
    caretButton: { height: '100%' },
    caretButtonLabel: {
        display: 'flex',
        justifyContent: 'center'
    },
    caretButtonSelected: {
        color: palette.primary.contrastText,
        background: palette.primary.main,
        '&:hover, &:focus': {
            color: palette.primary.contrastText,
            background:  darken(palette.primary.main, .2),
        }
    },
    'rotate-90-cw': { transition: 'transform 200ms', transform: 'rotate(90deg)' }
}))

const Collapsible = ({ open, header, children, onClick, enableCollapse, showCaret = true, isSelected }) => {
    const { t } = useTranslation()
    const _classes = useClasses()
    const [isOpen, setIsOpen] = useState(open || !enableCollapse)
    const _onClick = onClick ? onClick : () => setIsOpen(isSelected)

    useEffect(() => {
        if (isOpen !== open && enableCollapse) {
            setIsOpen(open)
        }
    }, [open])

    return (
        <div className={_classes.root}>
            <div className={classnames((showCaret && enableCollapse) ? _classes.header : _classes.fullHeader)}>
                <div className={classnames(_classes.headerComponent, {[_classes.headerComponentSelected]: isSelected})}>
                    {header}
                </div>
                {showCaret && enableCollapse &&
                    <Button
                        classes={{root: classnames(_classes.caretButton, {[_classes.caretButtonSelected]: isSelected}), label: _classes.caretButtonLabel}}
                        onClick={_onClick}
                        aria-expanded={String(isOpen)}
                        aria-label={isOpen
                            ? t('Collapse Group')
                            : t('Expand Group')
                        }
                    >
                        <FontAwesomeIcon
                            icon={['tcs', 'arrow-right']}
                            className={classnames(
                                _classes.caret,
                                { [_classes['rotate-90-cw']]: isOpen }
                            )}
                        />
                    </Button>
                }
            </div>
            <div>
                {isOpen && children}
            </div>
        </div>
    )
}

export default Collapsible
