import React, { useState, useMemo, useEffect, useContext } from 'react'
import Zoom from '@material-ui/core/Zoom'
import map from 'tcweb-material-components/core/poly/map'
import count from 'tcweb-material-components/core/poly/count'
import Dialog from 'tcweb-material-components/core/dialog'
import IconButton from 'tcweb-material-components/core/iconButton'
import get from 'tcweb-material-components/core/poly/get'
import { darken } from '@material-ui/core/styles/colorManipulator'

import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

import { decodeText } from '../../utilities/helpers'
import LocaleContext from '../context/localeContext'
import useLocation from '../../hooks/useLocation'
import Link from './link'
import Collapsible from './collapsible'
import CollapsibleList from './collapsibleList'

const PADDING_LEFT = 20
const MOBILE_TOGGLE_HEIGHT = 56

const useClasses = makeStyles(({ gutters, breakpoints, navigation, palette }) => ({
    aside: {
        [breakpoints.up('md')]: {
            minWidth: '240px',
            width: '240px'
        },
        [breakpoints.up('lg')]: {
            minWidth: '320px',
            width: '320px'
        }
    },
    list: {
        [breakpoints.up('md')]: {
            left: 0,
            position: ({ listPosition }) => listPosition,
            top: ({ listTop }) => listTop || navigation.desktop.height * navigation.desktop.number,
            minWidth: '240px',
            width: '240px',
            overflowY: 'auto',
            height: ({ listHeight }) => listHeight,
            borderRight: `1px solid ${palette.border.primary}`
        },
        [breakpoints.up('lg')]: {
            minWidth: '320px',
            width: '320px'
        }
    },
    sublist: {
        listStyleType: 'none',
        paddingLeft: 0
    },
    header: {
        display: 'block',
        width: '100%',
        padding: '20px',
        '&:hover, &:focus': {
            textDecoration: 'none'
        }
    },
    selectedHeader: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        '&:hover, &:focus': {
            color: palette.primary.contrastText,
            backgroundColor: darken(palette.primary.main, .2),
        }
    },
    heading: { marginTop: 0, marginBottom: 0 },
    subItemLink: {
        width: '100%',
        display: 'block',
        padding: '20px 20px 20px 40px',
        textTransform: 'capitalize',
        fontSize: '1em', // Override link font size from '1rem'
        justifyContent: 'flex-start',

        '&:hover, &:focus': {
            textDecoration: 'none',
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText
        },

        '& div': {
            position: 'relative',

            '&:before': {
                borderLeft: '2px solid transparent',
                height: '100%',
                content: '" "',
                position: 'absolute',
                top: 0,
                transform: `translateX(-${PADDING_LEFT}px)`
            }
        }
    },
    selectedSubItemLink: {
        textDecoration: 'none',
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,

        '&:hover, &:focus': {
            textDecoration: 'none',
            backgroundColor:  darken(palette.primary.main, .2),
            color: palette.primary.contrastText
        },

        '& div': {
            '&:before': {
                borderColor: palette.primary.contrastText
            }
        }
    },
    borderedSubItem: {
        position: 'relative',
        '&:before': {
            borderLeft: `2px solid ${palette.primary.main}`,
            content: '" "',
            position: 'absolute',
            top: 0,
            bottom: 0,
            transform: `translateX(-${PADDING_LEFT}px)`
        },

        '&:hover, &:focus': {
            '& div': {
                '&:before': {
                    borderColor: palette.primary.contrastText
                }
            }
        }
    },
    specialBorderFirst: {
        '&:before': {
            top: `${PADDING_LEFT}px`
        }
    },
    specialBorderLast: {
        '&:before': {
            bottom: `${PADDING_LEFT}px`
        }
    },
    tocToggle: {
        backgroundColor: palette.primary.main,
        borderColor: 'transparent',
        margin: 'auto',
        width: `${MOBILE_TOGGLE_HEIGHT}px`,
        height: `${MOBILE_TOGGLE_HEIGHT}px`,
        position: 'fixed',
        bottom: `calc(${navigation.height} + ${gutters.page.xs})`,
        right: gutters.page.xs,
        zIndex: 3,
        [breakpoints.up('sm')]: {
            bottom: `calc(${navigation.height} + ${gutters.page.sm})`,
            right: gutters.page.sm
        }
    },
    dialogPaper: {
        paddingLeft: 0,
        paddingRight: 0
    },
    dialogTitle: {
        paddingLeft: '20px',
        paddingRight: '20px'
    }
}))

const TableOfContents = ({ items, isDraft }) => {
    const { locale } = useContext(LocaleContext)
    const replaceRegex = new RegExp(`${locale}|\\/`, 'g')
    const { t } = useTranslation()
    const { navigation, breakpoints, palette } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'), { defaultMatches: true })
    const [listHeight, setListHeight] = useState('100%')
    const [listTop, setListTop] = useState(null)
    const [listPosition, setListPosition] = useState('initial')
    const [isTocOpen, setIsTocOpen] = useState(false)
    const _classes = useClasses({ listHeight, listTop, listPosition })
    const { location } = useLocation()
    const initialIndex = useMemo(
        () => (isDraft ? [0] : [
            items.findIndex((item) => item.path.replace(/\//g, '') === location.pathname.replace(replaceRegex, ''))
        ]),
        [location, isDraft]
    )
    const setSmoothScroll = (path, subItemAnchor) => () => {
        setIsTocOpen(false)

        if (path !== location.pathname) return

        const html = document.getElementsByTagName('html')[0]
        html.classList.toggle('smooth-scroll')

        if (subItemAnchor) {
            const heading = document.getElementById(subItemAnchor.replace('#', ''))
            heading.tabIndex = 1
            setTimeout(() => {
                heading.focus()
                html.scrollTo(0, heading.offsetTop - (isMobile ? 50 : 100))
            }, 1)
        }
        setTimeout(() => html.classList.toggle('smooth-scroll'), 1000)
    }

    const content = (
        <aside className={_classes.aside} aria-label={t('Secondary Navigation')} role='navigation'>
            <CollapsibleList
                className={_classes.list}
                initialOpen={initialIndex}
                enableExpandAll={count(items) > 1}
            >
                {map(
                    (item, index) => (
                        <Collapsible
                            key={item.path}
                            header={
                                <Link
                                    onClick={setSmoothScroll(item.path)}
                                    href={item.path}
                                    classes={{
                                        root: classnames(_classes.header, { [_classes.selectedHeader]: initialIndex[0] === index && !location.hash })
                                    }}
                                    aria-label={t('slug:go-to-title', { title: decodeText(get(item, 'title')) })}
                                >
                                    <h1 className={classnames('h4', _classes.heading)}>{decodeText(get(item, 'title'))}</h1>
                                </Link>
                            }
                            showCaret={count(item.headings) > 0}
                            enableCollapse={count(items) > 1}
                            isSelected={initialIndex[0] === index && !location.hash}
                        >
                            {count(item.headings) > 0 && (
                                <ul className={_classes.sublist}>
                                    {map((subItem, index) => {
                                        const isCurrent = location.hash === subItem.anchor

                                        return (
                                            <li key={subItem.anchor} className={_classes.item}>
                                                <Link
                                                    style={{ paddingLeft: `${PADDING_LEFT * ((subItem.depth || 1) + 1)}px` }}
                                                    aria-label={t('slug:go-to-title', { title: get(subItem, 'title', '') })}
                                                    className={classnames(_classes.subItemLink, {
                                                        [_classes.selectedSubItemLink]: isCurrent,
                                                        [_classes.borderedSubItem]: subItem.depth > 1,
                                                        [_classes.specialBorderFirst]:
                                                            subItem.depth > 1 && get(item, ['headings', index - 1, 'depth']) !== subItem.depth,
                                                        [_classes.specialBorderLast]:
                                                            subItem.depth > 1 && get(item, ['headings', index + 1, 'depth']) !== subItem.depth
                                                    })}
                                                    onClick={setSmoothScroll(item.path)}
                                                    href={`${item.path}${subItem.anchor}`}
                                                >
                                                    <div>{decodeText(get(subItem, 'title', ''))}</div>
                                                </Link>
                                            </li>
                                        )
                                    }, item.headings)}
                                </ul>
                            )}
                        </Collapsible>
                    ),
                    items
                )}
            </CollapsibleList>
        </aside>
    )

    const openToc = () => setIsTocOpen(true)
    const closeToc = () => setIsTocOpen(false)

    useEffect(() => {
        if (location.hash) {
            const timeoutHandler = setTimeout(() => {
                const anchor = document.querySelector(location.hash)
                const html = document.getElementsByTagName('html')[0]

                if (!anchor) return

                html.classList.toggle('smooth-scroll')
                anchor.focus()
                anchor.scrollIntoView()
                html.classList.toggle('smooth-scroll')
            }, 500)

            return () => {
                clearTimeout(timeoutHandler)
            }
        }
    }, [])

    useEffect(() => {
        if (isMobile) return

        const getListHeight = () => {
            const windowOffset = window.scrollY
            const windowHeight = window.innerHeight
            const headerElem = document.getElementsByTagName('header')[0]
            const mainElem = document.getElementsByTagName('main')[0]
            const footerOffset = document.getElementsByTagName('footer')[0].offsetTop
            let newListHeight = footerOffset - windowOffset > windowHeight ? windowHeight : footerOffset - windowOffset - headerElem.offsetHeight

            if (windowOffset === 0) {
                newListHeight = footerOffset - windowOffset > windowHeight ? windowHeight : mainElem.offsetHeight
                setListTop(headerElem.offsetHeight * navigation.tablet.number - 2) // Minus 2 for the border heights
                setListHeight(newListHeight)
            } else if (windowOffset <= headerElem.offsetHeight) {
                setListTop(headerElem.offsetTop + headerElem.offsetHeight - windowOffset)
                setListHeight(newListHeight - (headerElem.offsetTop + headerElem.offsetHeight - windowOffset))
            } else {
                setListTop(headerElem.offsetHeight)
                if (newListHeight === windowHeight) {
                    setListHeight(newListHeight - headerElem.offsetHeight)
                } else {
                    setListHeight(newListHeight)
                }
            }
            setListPosition('fixed')
        }

        document.addEventListener('scroll', getListHeight)

        return () => {
            document.removeEventListener('scroll', getListHeight)
        }
    }, [isMobile])

    if (isMobile) {
        return (
            <>
                <Zoom in style={{ transitionDelay: '500ms', animation: 'shadow-pulse 1s 0.5s 2' }}>
                    <IconButton classes={{ root: _classes.tocToggle }} aria-label={t('Open Table of Contents')} onClick={openToc}>
                        <FontAwesomeIcon icon={['fas', 'bars']} size='lg' color={palette.button.contained.primary.color} />
                    </IconButton>
                </Zoom>
                <Dialog
                    color='primary'
                    title={t('Table of Contents')}
                    fullScreen
                    open={isTocOpen}
                    onClose={closeToc}
                    disableCloseButton={false}
                    PaperProps={{
                        className: _classes.dialogPaper
                    }}
                    DialogTitleProps={{
                        className: _classes.dialogTitle
                    }}
                    CloseButtonProps={{
                        'aria-label': t('Close')
                    }}
                >
                    {content}
                </Dialog>
            </>
        )
    }

    return content
}

export default TableOfContents
